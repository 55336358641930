.marketing-section {
  padding: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 40px 0 40px 0;
  padding-top: 200px;
  padding-bottom: 200px;
  background-position-x: 62% !important;

  @include screen(lg) {
    padding: 100px 100px 80px 100px;

  }

  a.text-center.btn.btn-primary.market-button {
    font-size: 16px;
    color: #000;
  }
  a.text-center.btn.btn-primary.market-button:hover{
    color: white;
  }

  .wpb_raw_html {
    margin-bottom: 0;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 5px solid yellow;
    position: relative;
  }


  .subhead {
    font-size: 24px;
    @extend .font-primary;
    padding-bottom: 10px;
    border-bottom: 4px solid $color-primary;
    text-shadow: 0px 0px 8px black;
    color: #fff;
    @include screen(md) {
      font-size: 37px;
    }
    @include screen(lg) {
      padding-bottom: 20px;
    }
  }

  p {
    color: #ffffff;
    @extend .font-base;
    font-size: 18px;
    margin-top: 10px;
    text-transform: uppercase;
    @include screen(md) {
      font-size: 30px;
    }
  }

  .btn {
    @extend .font-base;
    font-size: 18px;
    color: #FFF;
    padding-right: 38px;
    @include screen(lg) {
      font-size: 30px;
    }
    @include screen(xl) {
      font-size: 34px;
    }
  }

  .vc_column-inner {
    padding: 0 !important;
  }

}

.marketing-section-2 {
  background-position: center;
  @include screen(lg) {
    padding: 138px 100px 104px 100px;

  }

  .marketing-col-right {
    max-width: 700px;
    margin: auto;
    @include screen(lg) {
      max-width: none;
      padding: 0;
    }
  }
}

.marketing-col-left {
  max-width: 700px;
  margin: auto;
  z-index: 1;
  @include screen(lg) {
    max-width: none;
    padding: 0;
    & > img {
      display: block !important;
    }
  }

  & > img {
    display: none;
  }
}

.marketing-row {
  margin: 0;
}

.test-thing-2,
.test-thing {
  position: relative;
}

#trigger4,
#trigger42 {
  position: relative;
  top: -365px;
}

@media only screen and (max-width: 768px) {
  .product-highlight {
    display: none;
  }
}

#gform_submit_button_2 {
  background-color: $color-primary;
  @include screen(md) {
    background-color: black;
    color: $color-sixth;
  }
}

#newsletter-section {
  background-color: transparent;

  @include screen(md) {
    background-color: #e4ce00;

  }

  h2.subscribe-news {
    text-align: center;
    padding-top: 20px;
  }

  .gform_wrapper li.hidden_label input {
    margin-top: 0;
  }

  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: 7px 4px !important;

  }

}

.market-section-top {
  text-align: center;
  padding: 20px !important;

  .market-title {
    font-size: 26px;
    color: $color-primary;
  }

  .market-info {
    color: $color-sixth;
    font-size: 18px;
  }
}

@media only screen and (min-width: 641px){
.gform_wrapper form.gf_simple_horizontal div.ginput_container, .gform_wrapper form.gf_simple_horizontal ul li.gfield {
  margin-top: 0!important;
  width: 260px;
}
}

.feature-section-2 {
  font-size: 36px;
  background-color: #000;
  background-position-x: 24%;
  background-repeat: no-repeat;
  background-size: cover !important;

  @include screen(md) {
    padding-top: 100px;

  }

  .feature-row {
    background-color: rgba(0,0,0,0.4);
    padding-top: 50px;
    padding-bottom: 50px;

    @include screen(lg){
      background-color: transparent;
    }
  }

  .featured-product-tagline {
    color: $color-primary;
    font-size: 38px;
    line-height: 1.1em;
  }

  .featured-product-sub {
    color: $color-sixth;
    width: 350px;
    margin-bottom: 25px;
    line-height: 1.2;
    font-size: 18px;

  }

  .featured-product {
    -webkit-box-align: center;
    -webkit-box-pack: center;
    display: -webkit-box;
    @include screen(md) {
      float: left;
    }
  }

  .just-title {
    border-left: 5px solid #e4ce00;
    padding-left: 8px;
  }

  ul.featured-info {
    color: #fff;
    list-style: none;
    text-transform: uppercase;
    font-size: 18px;
    padding: 0;
  }

}

.featured-button {
  text-align: center;
  @include screen(md) {
    text-align: left;
  }
}

.mobile-featured-product {
  column-count: 2;

  .mobile-product-title {
    text-align: center;
  }

}

.vc_row.wpb_row.vc_row-fluid.marketing-section.vc_custom_1566565808363.vc_row-has-fill.lazy {
  background-position-x: 62% !important;
}

.mobile-featured-left .vc_column-inner {
  padding-right: 0px !important;
}
.mobile-featured-right .vc_column-inner {
  padding-left: 0px !important;
}
