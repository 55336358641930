@import "global/helpers.scss";

/***--HEADER 2--**/
/***--MOBILE--**/
#wrapper-navbar #mobile-nav-toggle,
#wrapper-navbar #mobile-cart-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 15px;
  font-size: 30px;
  z-index: 2;

  &:hover {
    text-decoration: none;
  }

  .cart-count {
    color: $color-sixth;
    font-size: 20px;
    margin-left: 5px;
  }
}

/***--MOBILE--MENU--**/
.mm-menu.mm-theme-dark .mm-search input {
  border-radius: 0px;
  padding: 16px 10px;
  height: auto;

  &::placeholder  {
    color: $color-secondary;
  }
}

.wpmm-header-image {
  min-height: 63px;
}

.mm-listview > li > a, .mm-listview > li > span {
  @extend .font;
  font-size: 1.5em;
  padding: 1em;
  text-transform: uppercase;
}

.mm-navbars-top {
  border-color: $color-third;

  input[placeholder] {
    background-color: $color-fourth !important;
    color: $color-secondary !important;
    @extend .font;
    padding: 9px 0px 5px 5px;
  }
}

.mm-listview > li, .mm-listview > li .mm-next, .mm-listview > li .mm-next:before, .mm-listview > li:after {
  border-color: $color-fourth;
}

.logo-col {
  text-align: center;

  a,
  h1 {
    margin-left: auto;
    margin-right: auto;
  }
}

.navbar .container {
  display: block;
}

#wrapper-navbar {
  position: fixed;
  width: 100%;
  z-index: 1;
  top: 0;

  .admin-bar & {
    top: 32px;
    @media(max-width: 782px) {
      top: 46px;
    }
  }

  .custom-logo-link {
    @include screen(col) {
      justify-content: center;
      margin: 0px 60px 0px 60px;
    }

    img {
      width: 60px;
      height: 60px;
      @include screen(lg) {
        width: 110px;
        height: 110px;
      }
    }
  }

  #mobile-cart-toggle {
    left: auto;
    right: 15px;
  }

  nav.navbar a.sticky-logo {
    top: 10px;
  }
}

nav.navbar {
  padding: 0;
  background-image: url("/wp-content/uploads/sites/14/2019/08/header-texture-1.gif");
  background-color: black;
  @include screen(custom, max, 991) {
    height: 70px;
    position: relative;
  }

  .keyword-search {
    flex-grow: 2;
  }

  a.sticky-logo, #navbarNavDropdown, .user-session {
    display: block;
    float: left;
  }

  .sticky-logo {
    @include screen(custom, max, 991) {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      top: 0;
      text-align: center;
      img {
        max-width: 180px;
      }
    }
    @include screen(lg) {
      margin-top: 27px;
      width: 19%;
    }
  }

  #navbarNavDropdown {
    display: none;
    @include screen(lg) {
      display: block;
      width: 60%;
    }

    #main-menu {
      > li {
        a {
          font-size: 22px;
          @include screen(lg) {
            padding: 0 15px 0 15px;
          }
          @include screen(xl) {
            padding: 0 25px 0 25px;
          }

        }

        > a {
          line-height: 107px;
        }

        .facetwp-facet-search {
          margin-top: 40px;

          .facetwp-search-wrap {
            position: relative;
          }

          .facetwp-btn {
            top: 0;
            right: 5px;
            line-height: 1;
            position: absolute;
            cursor: pointer;
            opacity: 0.5;

            &:before {
              display: inline-block;
              content: '';
              width: 20px;
              height: 20px;
              background: url("/wp-content/uploads/sites/14/2019/09/icon-search-dark.png") no-repeat;
              background-size: 20px 20px;
            }
          }

          input[type=text] {

            background-color: $color-fourth;
            border:none;
            padding-left: 5px;
            font-size: 80%;
            line-height: 1.8em;
            width: 100%;
            font-size: 16px;

            &::placeholder {
              color: $color-secondary;
            }
            &:-ms-input-placeholder {
              color: $color-secondary;
            }
            &::-ms-input-placeholder {
              color: $color-secondary;
            }
          }
        }
      }

      li:last-child {
        a {
          margin-right: 0;
        }
      }

      .dropdown-menu {
        margin: 0;
        border-radius: 0;
        background: #000;
      }
    }
  }

  .user-session {
    display: none;
    @include screen(lg) {
      display: block;
      width: 15%;
      margin-top: 15px;
      text-align: right;
    }

    .account-links {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        list-style: none;
      }

      a {
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
          text-decoration: none;
        }
      }

      .account-logout {
        &:before {
          content: "|";
          display: inline-block;
          margin-left: 6px;
          margin-right: 10px;
          @extend .font-base;
        }
      }
    }

    .sumatra-mini-cart-toggle {
      font-size: 16px;
      display: flex;
      align-items: center;

      &:hover {
        text-decoration: none;
      }

      .cart-count {
        color: $color-sixth;
        margin-right: 5px;
      }

      .fa {
        font-size: 28px;
        color: $color-primary;
      }

      .left-cart {
        flex-grow: 1;
      }

      .cart-total {
        color: $color-sixth;
        flex-grow: .8;
      }
    }
  }

  #navbarNavDropdown #main-menu .dropdown-menu {
    width: 236px !important;
  }
}

.search-me.col-12 {
  text-align: center;
  top: 17px;

  .facetwp-facet {
    margin-bottom: 0px;
  }

  .facetwp-search {
    width: 300px;
  }
}
a.nav-link {
  color: #bfbfbf !important;
  &:hover{
  color: #e4ce00 !important;
  }
}

