.btn {
	@extend .font-base;
	color: #fff;
	display: inline-block;
	padding: 8px 25px 8px 20px;
	position: relative;
	border: none;
	border-radius: 3px;
	font-size: 16px;
	text-transform: uppercase;
	transition: all ease-in-out 250ms;

	&:after {
		content: '\f105';
		font-family: FontAwesome;
		font-size: 22px;
		position: absolute;
		top: 47%;
		transform: translateY(-50%);
		line-height: normal;
		right: 10px;
	}

	&:hover {
		cursor: pointer;
	}

	&:focus {
		box-shadow: none;
	}

	@include screen(lg) {
		padding: 10px 40px 10px 40px;
	}
}

.btn-lg {
	font-size: 22px;
	padding: 15px 30px 15px 20px;

	&:after {
		font-size: 30px;
	}

	@include screen(lg) {
		padding: 15px 80px 15px 80px;
	}
}

.btn.btn-primary {
	color: #000;
	background: $color-primary;
	font-weight: 500;

	&:hover {
		color: #fff;
		background-color: darken($color-primary, 15%);
	}

	&:after {
		color: black !important;
	}

	&:after {
		color: $color-primary-dark;
	}
}

.btn.btn-secondary {

	@extend .font;

	background-color: $color-secondary;
	font-size: 18px;
	text-transform: uppercase;
	padding: 10px 30px;
	color: $color-third;

	&:hover {
		color: #fff;
		background-color: darken($color-secondary, 15%);
	}

	&:after {
		font-family: FontAwesome;
		color: $color-primary;
	}
}

.btn.btn-outline-primary {
	background: linear-gradient(135deg, transparent 8px, $color-primary 8px);
	margin-top: 0;
	padding: 10px 30px 10px 30px;
	transition: .4s;

	&:hover {
		background: linear-gradient(135deg, transparent 8px, $color-secondary 8px);
	}
}

.btn.btn-third {
	background: linear-gradient(135deg, transparent 8px, #58585A 8px);
	color: #fff;
	font-size: 18px !important;
	text-transform: uppercase;
	margin-top: 0;
	padding: 8px 0;
	transition: .4s;
	border-radius: unset;
	border: none;

	&:hover {
		background: linear-gradient(135deg, transparent 8px, #000 8px);
	}

	&:after {
		display: none;
	}
}

.btn.btn-info {
	background: $color-primary;
	color: #000000;

	&:hover {
		background: #231f20;
		color: #ffffff;

		&:after {
			color: #ffffff !important;
		}
	}
}

.btn.btn-info:after {
	font-family: FontAwesome;
	color: #9a9999;
}