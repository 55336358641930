// This is an empty scss file for your custom styles - Please add your super duper awesome mega design styles here
@import 'globals';
@import 'header';
@import 'footer';
@import 'woocommerce/mini-cart';
@import 'woocommerce/single-product';
@import 'woocommerce/category';
@import 'woocommerce/wishlist';
@import 'woocommerce/account';
@import 'woocommerce/checkout';
@import 'woocommerce/general';
@import 'featured-item';
@import 'promo';
@import 'visual-timeline';
@import 'mmenu';
@import 'algolia';
@import 'promo-2';
@import 'newsletter-form';
@import 'marketing-section';
@import 'dealer-locator';
@import 'sponsors';

body.sticky {
	.wrapper {
		@include screen(lg) {
			padding-top: 83px;
		}
	}
}

#customer_login {
	@include screen(lg) {
		flex-wrap: nowrap;
	}

	.u-column1 .login .form-row:nth-child(3) {
		float: right;
	}
}

.woocommerce-LostPassword.lost_password {
	margin-bottom: 0;
}

.register .form-row .btn.btn-primary,
.login .form-row .btn.btn-primary {
	padding: 10px 30px;
}

.content-element-no-margin {
	.wpb_text_column.wpb_content_element {
		margin: 0;
	}
}

.sumatra-mini-cart-toggle {
	a {
		display: flex;
		align-items: center;
	}
}

#place_order {
	background: linear-gradient(135deg, transparent 8px, $color-primary 8px);
}

#order_review_heading {
	margin-top: 20px;
	@include screen(lg) {
		margin-top: 0;
	}
}

.input-group-btn > .btn {
	border: none;
	margin-top: 0;
}

#searchform > label {
	display: none;
}

/*-------------------WOOCOMMERCE START--------------------*/

.woocommerce-info {
	border-top-color: $color-primary;

	&:before {
		color: $color-primary;
	}
}

#woocommerce-wrapper {
	padding-top: 0;
	padding-bottom: 0 !important;
}


.woocommerce ul.products {
	margin-bottom: 0;
}

.woocommerce form .form-row-last, .woocommerce-page form .form-row-last,
.woocommerce form .form-row-first, .woocommerce-page form .form-row-first {
	display: block;
}

.woocommerce-checkout #payment div.payment_box .wc-credit-card-form-card-cvc {
	padding: 3px;
}

.woocommerce-checkout .checkout .payment-fixed {
	background-color: #ffffff;
}


/*-------------------WOOCOMMERCE END--------------------*/

/*-------------------HOME START-------------------------*/

body.home {
	.wpb_revslider_element.wpb_content_element {
		margin-bottom: 0;
	}
}

a#instagram-icon:hover {
	text-decoration: none;
}

.home {

	.product-highlight {
		background-size: cover;
	}

	.suma-featured-item {

		display: block;
		padding-left: 15px;
		padding-right: 15px;

		.left-featured,
		.right-featured {
			text-align: center;
			width: auto;
		}

		.line-1 {
			position: static;
		}

		.line-2 {
			margin-top: 0;
		}

		@include screen(xl) {

			display: flex;

			.left-featured {
				width: 60%;
			}
			.right-featured {
				text-align: right;
				width: 40%;
			}

			.line-1 {
				position: static;
			}

			.line-2 {
				margin-top: 0;
			}
		}
	}

	.marketing-section {
		#predator-bullets {
			ul {
				padding: 0 15px 0 30px;
			}
		}
	}
}

.hero-line-1 {
	font-family: proxima-nova !important;
}

.hero-line-2 {
	font-family: proxima-nova !important;
}

/*-------------------HOME END-------------------------*/

/*-------Parralax-----*/

.parallax {
	background: #231f20;
	color: white;
	padding: 0px;
	@extend .font-base;

	.vc_column-inner {
		padding: 0px !important;
	}

	p {
		font-size: 18px;
		max-width: 920px;
		text-transform: uppercase;
		text-align: justify;
		margin: 0 auto;
		padding: 45px 25px;
		color: #af9c86;
		@include screen(md) {
			font-size: 30px;
			padding: 0;
			color: #ffffff;
		}
	}

	.wpb_raw_code {
		margin-bottom: 0;
	}

	.parallax-mobile {
		height: 180px;
		display: block;
		background-image: url(/wp-content/uploads/sites/2/2018/03/stealth-cam-lifestyle-2-mobile.jpg);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		@include screen(md) {
			background-image: none;
			display: none;
		}
	}

	@include screen(md) {
		background-position: center;
		background-image: url(/wp-content/uploads/sites/2/2018/03/stealth-cam-lifestyle-2.jpg);
		background-position-y: top;
		background-size: cover;
		background-attachment: fixed;
		font-size: 22px;
		padding: 100px 75px !important;
		width: 800px;
	}
	@include screen(lg) {
		padding: 210px 175px !important;
	}
}

/**--slick slider---footer--*/

.slick-slider {
	background: #303030;
	padding: 15px 7% 40px 5%;
	display: flex !important;
	align-items: center !important;
	color: $color-primary;
	font-size: 29px;
	line-height: 9px;
}

.slider-title {
	text-align: center;
	font-size: 1em;
	background: #303030;
	color: $color-third;
	padding: 13px;
	text-transform: uppercase;
	@extend .font-primary;
}

/**--slick slider---footer END--*/

/**--category--*/
#title-row {
    color: white;
    padding-top: 80px;
    padding-bottom: 40px;
    background-color: black;
    @include screen(md) {
        padding-top: 210px !important;
        padding-bottom: 260px !important;
    }
    .wpb_wrapper {
        max-width: 100%;
        margin: 0;
    }
    h1 {
        @extend .font-primary;
        font-size: 26px;
        text-shadow: rgb(0, 0, 0) 0px 0px 8px;
        text-align: center;
        top: 17px;
        position: relative;
        @include screen(md) {
            font-size: 72px;
            top: 40px;
            position: relative;
        }
    }
    p {
        margin-bottom: 0;
        @extend .font-secondary;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        @include screen(md) {
            font-size: 1.564vw;
            line-height: 2.7vw;
        }
    }
}

.breadcrumb-container {
	margin-bottom: 20px;
	margin-top: 0;
	@include screen(md) {
		margin-top: 30px;
	}

	#breadcrumbs {
		@extend .font-base;
		text-transform: uppercase;
		font-size: 18px;
		display: none;
		@include screen(md) {
			display: block;
		}

		span[typeof="v:Breadcrumb"] {
			display: flex;
			align-items: center;
		}

		.divider {
			background: #f26522;
			border: 0;
			width: 14px;
			list-style: none;
			height: 6px;
			margin: 0 15px;
		}
	}
}

.fa.fa-facebook-square {
	color: #e4ce00;
}

.fa.fa-twitter-square {
	color: #e4ce00;
}

.fa.fa-youtube-square {
	color: #e4ce00;
}

.fa.fa-instagram {
	color: #e4ce00;
}

#ywar_reviews #reviews_summary h3,
.single-product div.product #reviews-overview #ywar_reviews #reviews_summary .woocommerce-product-rating {
	display: none;
}

.vc_col-has-fill > .vc_column-inner, .vc_row-has-fill + .vc_row-full-width + .vc_row > .vc_column_container > .vc_column-inner, .vc_row-has-fill + .vc_row > .vc_column_container > .vc_column-inner, .vc_row-has-fill + .vc_vc_row > .vc_row > .vc_vc_column > .vc_column_container > .vc_column-inner, .vc_row-has-fill + .vc_vc_row_inner > .vc_row > .vc_vc_column_inner > .vc_column_container > .vc_column-inner, .vc_row-has-fill > .vc_column_container > .vc_column-inner, .vc_row-has-fill > .vc_row > .vc_vc_column > .vc_column_container > .vc_column-inner, .vc_row-has-fill > .vc_vc_column_inner > .vc_column_container > .vc_column-inner, .vc_section.vc_section-has-fill, .vc_section.vc_section-has-fill + .vc_row-full-width + .vc_section, .vc_section.vc_section-has-fill + .vc_section {
	padding-top: 0 !important;
}

.gform_wrapper select {
	height: 36px;
}

input[type="text"], input[type="address"], input[type="email"], input[type="tel"], input[type="password"], textarea {
	background-color: #f2f2f2;
	outline: 0;
	border: 1px solid #ced4da;
}

.dropdown-toggle::after {
	font-family: FontAwesome;
	content: '\f107 ';
	vertical-align: inherit;
	border-left: none;
	border-right: none;
	border-top: none;
}

#searchform {
	.input-group .form-control:not(:last-child) {
		border-radius: .25rem;
	}

	.input-group-btn {
		margin-left: 20px;
	}
}

#iframe14882 {
	border-width: 0 !important;
}

.content-area .entry-header .entry-title {
	margin-bottom: 30px;
}

.single .content-area .entry-header .entry-title {
	text-align: center;
}

.vc_btn.vc_btn-primary.btn.btn-primary {
	border: none;
	border-radius: 0;

	&[disabled] {
		background: linear-gradient(135deg, transparent 8px, #db2128 8px);
	}
}

// IE11
@media all and (-ms-high-contrast: none) {
	#suma-dealer-locator .lookup .row-1 .btn-load {
		flex-basis: 20%;
	}
	#suma-dealer-locator .lookup .row-1 .address-column {
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
}

#wrapper-navbar .skip-link {
	position: fixed !important;
}

.slider-title .slick-track {
	display: flex;
	align-items: center;

	.slick-slide img {
		margin: auto;
	}
}

.product-row-alt form.cart {
	flex-direction: column;
	align-items: flex-start !important;
}

.product-row-alt .variations {
	margin-bottom: 0 !important;
}

.woocommerce div.product form.cart .variations select {
	padding: 4px;
}

.woocommerce-variation-add-to-cart {
	display: flex !important;
	align-items: flex-end !important;
}

form.cart .single_add_to_cart_button {
	display: block;
	width: 65%;
}

.single_variation_wrap {
	width: 100%;
}

.single-product div.product form.cart .variations label {
	@extend .font;
}

.woocommerce-account {
	.entry-content:after {
		display: table;
		clear: both;
		content: "";
	}

	.woocommerce-MyAccount-navigation, .woocommerce-MyAccount-content {
		float: left;
	}

	.woocommerce-MyAccount-content {
		margin-left: 2%;
	}
}

footer.entry-footer {
	display: none;
}

.dropdown-submenu {
	position: relative;
}

.dropdown-submenu > ul.dropdown-menu {
	left: 100%;
	top: 0;
	margin-top: 0;
}

.dropdown-submenu:hover > ul.dropdown-menu {
	display: block;
}

.dropdown-submenu:before {
	position: absolute;
	top: 14px;
	right: 5px;
	/* background: #fff; */
	display: inline-block;
	border-right: 7px solid transparent;
	border-bottom: 7px solid #ccc;
	border-left: 7px solid transparent;
	border-bottom-color: rgba(120, 120, 120, 1);
	content: '';
	transform: rotate(90deg);
}

span.woocommerce-input-wrapper {
	display: block;
	width: 100%;
}


.woocommerce-account {
	.entry-content:after {
		display: table;
		clear: both;
		content: "";
	}

	.woocommerce-MyAccount-navigation, .woocommerce-MyAccount-content {
		float: left;
	}

	.woocommerce-MyAccount-content {
		margin-left: 2%;
	}
}

footer.entry-footer {
	display: none;
}

.woocommerce-Addresses {
	@include screen(md) {
		.woocommerce-Address {
			width: 50%;
			float: left;

		}
	}

	.woocommerce-Address-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 90%;
	}

}

.woocommerce-account .addresses .title::after, .woocommerce-account .addresses .title::before {
	display: none;
}

.instagram-feed .wpb_raw_code.wpb_raw_html {
	margin-bottom: 20px;
}

.download img {
	margin-bottom: 20px;
}

.tax-download_category #content .breadcrumb-container,
.post-type-archive-downloads #content .breadcrumb-container {
	display: none;
}

.tax-download_category .page-header h1 {
	margin-bottom: 30px;
}

#input_1_7 {
	background-color: #f2f2f2;
	border: 1px solid #ced4da;
}

//FAQ
.wpb-js-composer .vc_tta-color-grey.vc_tta-style-classic .vc_tta-panel .vc_tta-panel-title > a {
	text-transform: initial;
}

//Blogs

.single-post,
.page:not(.woocommerce-account) {
	#single-wrapper,
	#page-wrapper {
		.container {

			max-width: 900px;

			.entry-header .entry-title {
				font-size: 24px;
				text-align: center;

				@include screen(lg) {
					font-size: 36px;
				}
			}

			.entry-content {
				overflow: hidden;
			}
		}
	}
}

.woocommerce-account {
	#page-wrapper {
		.entry-header .entry-title {
			font-size: 24px;
			text-align: center;

			@include screen(lg) {
				font-size: 36px;
			}
		}
	}
}

.blog {
	#wrapper-index {
		.container {
			max-width: 900px;

			.entry-header .entry-title {
				font-size: 24px;
			}
		}
	}
}

.woocommerce-checkout #place_order {
	background-color: $color-primary !important;
	color: #000;

	&:hover {
		background-color: #000 !important;
		color: #fff !important;
	}
}

.page-id-6185 .entry-title {
	display: none;
}

.page-id-6186 .entry-title {
	display: none;
}

.page-id-6220 .entry-title {
	display: none;
}

.page-id-6221 .entry-title {
	display: none;
}

.products .page-title {
	display: none;
}

.on-top {
	position: absolute !important;
	color: white;
	text-align: center;
	padding: 10px;
	bottom: 0;
	pointer-events: none;
}

.product-section {
	@include screen(md) {
		padding-top: 50px;
	}

	.wpb_column {

		h2 {

		}

		img {
			transition: opacity ease-in-out 250ms;
		}

		&:hover {
			img {
				opacity: 0.8;
			}

			.btn-primary {
				background-color: darken($color-primary, 15%);
				color: $color-sixth;
			}
		}
	}
}

.vc_row.wpb_row.vc_row-fluid.marketing-section.vc_custom_1566569574158.vc_row-has-fill.lazy {
    background-position-x: 61% !important;
    background-color: black;

}

.vc_row.wpb_row.vc_row-fluid.products-secondrow.lazy {

	padding-bottom: 50px;

}
.vc_row.wpb_row.vc_row-fluid.feature-section-2.lazy {
    background-color: black;
}

.vc_row.wpb_row.vc_row-fluid.product-highlight.vc_custom_1565981876122.vc_row-no-padding.lazy {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

.empty-space {
	display: none;
	@include screen(md) {
		display: block;
	}
}

#on-top h2 {
	font-weight: 700;
}

.mobile-product-title {
	font-size: 19px;
	font-weight: 700;
}

.product-search-form select {
	background-color: black;
	color: white;
	padding: 11px;
	border: none;
	padding-right: 40px;
	text-transform: uppercase;
}

.product-search-form button {
	padding: 10px;
	color: black;
	border: 1px solid black;
	background-color: transparent;
	text-transform: uppercase;
}

form.product-search-form {
	background-color: #ffe816;
	padding: 10px;
	text-align: center;

}

div#content {
	padding-top: 65px;
}

.feature-row.wpb_column.vc_column_container.vc_col-sm-6 {
    padding-top: 50px;
}


form.woocommerce-ordering {
	display: none;
}

button.facetwp-flyout-open {
	float: right;
	top: 40px;
	position: relative;
	right: 20px;
	background-color: #e4ce00;
	border: none;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	font-size: 20px;
	@include screen(md) {
		display: none;
	}

}

#left-sidebar {
	display: none;
	@include screen(md) {
		display: block;
	}
}

.q_icon_with_title.small.normal_icon.left_from_title.social {
	text-align: center;
}

i.qode_icon_font_awesome.fa.fa-user-plus.qode_iwt_icon_element {
	color: #656363;
}

nav.navbar.navbar-expand-lg {
	max-height: 111px;
}

#gform_confirmation_message_2 {
	padding-top: 20px;
}

#wpmm-menu-item-7402 {
	display: none;
	@include screen(md) {
		display: block;
	}
}

.facetwp-flyout {
	z-index: 9999999 !important;
}

.facetwp-flyout-fog {
	z-index: 9999998 !important;
}

.facetwp-flyout-wrap {
	position: relative !important;
}

.facetwp-flyout-close {
	display: block !important;
	text-align: center !important;
}

.lds-container {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: rgba(0, 0, 0, 0.2);
}

.lds-hourglass {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}

.lds-hourglass:after {
	content: " ";
	display: block;
	border-radius: 50%;
	width: 0;
	height: 0;
	margin: 6px;
	box-sizing: border-box;
	border: 26px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
}

.archive .page-title {
	font-size: 28px;
}

.inline-top {
	display: inline-flex;
	padding-bottom: 30px;
}

.showing-top {
	margin-right: 5px;
}

.facetwp-pager {
	font-size: 19px;

	a.facetwp-page.active {
		padding-right: 5px;
		padding-left: 5px;
		border: 1px solid;
	}

	a.facetwp-page {
		padding-right: 5px;
		padding-left: 5px;
		border: 1px solid;

	}
}

.fa.fa-user {
	color: #656363 !important;
}

.single-product.woocommerce div.product #tab-row .vc_tta-panels-container .vc_tta-panels .vc_tta-panel .vc_tta-panel-body .suma-specs ul li:before {
	background: #e0ca00 !important;
}
.products-grid {
    max-width: 1200px;
    margin: 50px auto !important;
}
button.facetwp-flyout-open {
    top: -13px;
}

@media only screen and (max-width: 425px) {
	.marketing-section  {
		background-position-x: 62% !important;
	}
}
.vc_custom_1568230409789{
	background-position-x: 62% !important;
}
#title-row, .vc_row {
	margin-right: 0;
}
.no-gutter{
padding-right:0;
	padding-left:0;
}
#gf_2, .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit]{
	margin-right:0 !important;
}