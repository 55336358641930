/*--product categorey Module--*/

#products-row {
    color: #000;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 45px 0;
    @include screen(lg) {
        padding-top: 125px;
        padding-bottom: 125px;
    }
    @include screen(md) {
        padding-top: 90px;
        padding-bottom: 90px;
    }
    ul.products {
        display: flex;
        flex-wrap: wrap;
        li.product:only-child {
            margin: auto;
            &:nth-child(odd) {
                margin-right: auto;
            }
        }
        li.product {
            position: relative;
            padding-bottom: 30px;
            width: 100%;
            @extend .font-base;
            &:before {
                position: absolute;
                bottom: 0;
                content: " ";
                width: 25%;
                height: 2px;
                background: #303030;
                right: 0;
                left: 0;
                margin: 0 auto;
            }

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                &:before, &:after {
                    display: none;
                }
            }
            @include screen(md) {
                &:nth-child(odd) {
                    margin-right: 5%;
                }
                &:nth-child(even) {
                    margin-left: 5%;
                    margin-right: 0;
                }
                width: 45%;
                float: left;

            }
            @include screen(lg) {
                width: 100%;
                padding-bottom: 0;
                &:nth-child(odd) {
                    margin-right: 0;
                }
                &:nth-child(even) {
                    margin-left: 0;
                }
                &:before {
                    displaY: none;
                }
                &:after {
                    width: 200px;
                    height: 2px;
                    content: " ";
                    background: #303030;
                    display: block;
                    margin-left: 310px;
                    margin-top: 20px;
                }
            }
            .woocommerce-LoopProduct-link{
                display:block;
                position: relative;
                overflow: hidden;
            }
            .row {
                @include screen(lg) {
                    display: flex;
                    flex-direction: row;
                }
            }
            .product-img-wrapper {
                text-align: center;
            }
            .suma_product_title {
                text-align: center;
                text-transform: uppercase;
                margin-top: 10px;
                @include screen(lg) {
                    margin-top: 0;
                    text-align: left;
                }
                a {
                    padding-left: 0 !important;
                    color: #000 !important;
                    @extend .font-primary;
                    font-size: 28px !important;
                }
            }

            .suma_sku {
                color: $color-primary;
                text-align: center;
                font-size: 1.25em;
                font-weight:normal;
                margin-bottom: 10px;
                @extend .font-base;
                @include screen(lg) {
                    text-align: left;
                }
            }
            .suma_product_description {
                max-width: 600px;
                font-size: 18px;
                line-height: normal;
                @extend .font-base;
            }
            .suma_rating {
                color: $color-fifth;
                font-size: 1.5em;
                .star-rating {
                    margin-bottom: 0;
                    @include screen(lg) {
                        float: right;
                    }
                }
            }
            img {
                max-width: 300px;
                margin: 0 auto;
                width:100%;
                @include screen(lg) {
                    display: inline-block;
                }
                vertical-align: top;
            }
            .suma_category_details {
                min-height: 90px;
                @include screen(lg) {
                    margin: 0 10px;
                }

                .shop_attributes {
                    display: none;
                }
            }
        }
    }
    .suma_category_action {
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;
        margin-top:15px;
        .price {
            width: 50%;
            order: 1;
            height: auto !important;
            margin-bottom: 0;
            line-height: normal;
            color: $color-secondary;
            @extend .font-secondary;
            @include screen(lg) {
                width: 100%;
                text-align: right;
            }
            .woocommerce-Price-amount {
                @extend .font-primary;
                font-size: 30px;
            }
        }
        .suma_category_price {
            width: 50%;
            order: 3;
            @include screen(lg) {
                width: 100%;
                order: 2;
                text-align: right;
            }
        }

        & > a {
            width: 50% !important;
            order: 2;
            text-align: right;
            margin-top: 0;
            @include screen(lg) {
                width: 100% !important;
                order: 3;
                margin-top: 8px;
            }
            .btn.btn-primary {
                //custom btn colors
                margin-top: 0;
                padding: 10px 25px 10px 15px;

                @include screen(xl) {
                    padding: 10px 30px 10px 30px;
                }
            }
        }
        .yith-wcwl-add-to-wishlist {
            width: 50%;
            order: 4;
            text-align: right;
            margin-top: 10px;
            @include screen(lg) {
                width: 100%;
            }
            .yith-wcwl-add-button {
                @extend .font-base;
                a {
                    padding-left: 25px;
                    position: relative;
                    &:before {
                        font-family: FontAwesome;
                        content: "\f08a";
                        position: absolute;
                        left: 0;
                    }
                }
            }
            .yith-wcwl-wishlistexistsbrowse{
                @extend .font;
                }
            }

        .feedback {
            display: none !important;
        }

        img {
            display: none !important;
        }
        .btn.btn-primary {
            margin-top: 10px;
        }
    }
    .shop_attributes {
        border: none;
        @include screen(lg) {
            margin-top: 10px;
        }
        tr, th, td, table {
            background: none !important;
            border-color: transparent;
            padding: 0;
        }
        th, tr {
            display: flex;
            align-items: center;
        }
        th {
            width: 50%;
            text-transform: uppercase;
            color: #6c6164;
            font-size: 18px;
            @extend .font-base;
            &:before {
                content: " ";
                background: $color-primary;
                border: 0;
                width: 14px;
                list-style: none;
                height: 6px;
                margin-right: 10px;
            }
        }
        td {
            width: 50%;
            font-size: 18px;
            line-height: 18px;
            font-style: normal;
            @extend .font-base;
        }
        tr {
            td {
                p {
                    a {
                        padding-left: 0 !important;
                    }
                }
            }
        }
        @include screen(lg) {
            tbody {
                display: flex;
                flex-wrap: wrap;
                tr {
                    width: calc(100% / 2);
                }
            }
        }
    }


}

#left-sidebar {
    .facetwp-facet-search {
        input[type=text] {
            padding-left: 5px;
            padding:5px;
        }
    }
}