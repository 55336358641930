/*----------FROM DAVID CSS Start----------*/

.footer-top {
    .content-boxes {
        padding:30px 15px 0;

        @include screen(md) {
            padding: 60px 15px 30px;
        }
    }
}

.email-signup-global {
    background-color: #e4ce00;
    padding-top: 10px;

}
#gf_2, #gform_submit_button_2{
    font-weight:300;
    background-color: black !important;
    color: white;
}
.subscribe-left {
    padding: 20px;
}
#gf_2, .gform_wrapper li.hidden_label input {
    margin-top: 0 !important;
}

.social-footer {
    left: 11px;
    position: relative;
}
.home .email-signup-global {
    display: none;
}

.content-boxes .box {
    margin-bottom: 30px;
    &:last-child .vc_column-inner .wpb_wrapper {
        margin-bottom: 0;
    }
    @include screen(lg) {
        margin-bottom: 0;
    }
}

.content-boxes .box .vc_column-inner {
    padding-left: 0;
    padding-right: 0;
}

.content-boxes .box .vc_column-inner .wpb_wrapper {
    margin: 0 auto;
}

.content-boxes .box .wpb_wrapper h2 {
    font-weight: 500;
    font-style: italic;
    font-size: 23px;
    text-transform: Capitalize;
    @include screen(lg) {
        font-size: 30px;
    }
}
.content-boxes .box .wpb_wrapper .q_icon_with_title {

    &:hover {
        cursor: pointer;
    }

}
.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title {
    @extend .font;

    font-size: 24px;
    font-style: normal;
    text-transform: uppercase;

}
.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title_holder {
    text-align: center;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title_holder > * {
    display: inline-block;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title_holder .icon_holder {
    min-width: 42px;
    padding-right: 4px;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title_holder .icon_holder > span {
    top: 0;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title_holder .icon_holder .qode_icon_font_awesome {
    font-size: 1.2em;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner .icon_title_holder h2 {
    vertical-align: baseline;
}

.content-boxes .box .wpb_wrapper .wpb_content_element {
    margin-bottom: 0;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p {
    position: relative;
    border-left: 5px solid #dddddd;
    padding-left: 10px;
    font-weight: 300;
    font-size: 18px;
    margin-left: 45px;
    margin-bottom: 0;
    line-height: 24px;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p:after {
    content: " ";
    position: absolute;
    top: 0;
    left: -5px;
    width: 5px;
    height: 4px;
    background: white;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p:before {
    content: " ";
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 5px;
    height: 4px;
    background: white;
}

.border-top-footer {
    background: $color-secondary;
    position: relative;
    line-height: 15px;
}

.border-top-footer:after {
    content: " ";
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 7px;
    background: $color-primary;
    position: absolute;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p {
    border-left: 0;
    padding-left: 0;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p:before {
    display: none;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p .hover-bar {
    height: 100%;
    width: 30px;
    position: absolute;
    left: -45px;
    text-align: right
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p .hover-bar .bar {
    background: #dddddd;;
    max-width: 5px;
    transition: max-width 0.5s;
    height: 100%;
    width: 30px;
    display: block;
    float: right;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner:hover p .hover-bar .bar {
    background: #96926d;
    max-width: 100%;
}

.content-boxes .box .wpb_wrapper .q_icon_with_title .icon_text_holder .icon_text_inner p .hover-bar .bar:before {
    height: 100%;
    content: " ";
    width: 5px;
    background: white;
    display: block;
    position: absolute;
    left: 6px;
}

/**-------------FROM DAVID CSS End----------*/

/**-----FOOTER START------------**/

footer {
    background-color: $color-secondary;
}

footer .footer_inner .footer_bottom_holder {
    padding: 45px 0;
}

.sticky footer, :not(.sticky) footer {
    .custom-logo-link {
        justify-content: center;
        display: flex
    }
    .foot-left {
        display: flex;
        justify-content: center;
        .navbar-brand {
            margin: auto;
        }
        @include screen(lg) {
            padding: 0px;
        }
    }
    .foot-middle {
        align-self: center;
        margin-top: 20px;
        @include screen(lg) {
            margin: 0;
        }
        @include screen(xl) {
            padding-left: 40px;
        }
    }
    .foot-right {
        color: white;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        text-align: left;
        &-cell {
            margin-top: 20px;
            @include screen(lg) {
                margin-top: 0;
            }
        }
        @include screen(md) {

        }
        @include screen(lg) {
            flex-direction: row;
            align-items: flex-start;
            text-align: left;
        }
        @extend .font-base;
    }
    .foot-right-cell.col-6 a {
        color: white;
    }

    #footer-menu {
        width: 100%;
        #footer-main-menu {
            padding-left: 0;
            margin-bottom: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .navbar-nav-footer {
            @extend .font-base;
            font-size: 18px;
            text-transform: uppercase;
            @include screen(lg) {
                display: flex;
                justify-content: space-between;
                padding: 0px;
            }

            li {
                list-style: none;
                width: 50%;
                text-align: left;

                a {
                    font-size:14px;
                    text-align: left;
                    padding: 0;

                    @include screen(lg) {
                        font-size:16px;
                        text-align: left;
                    }
                    color: #e4ce00 !important;
                    &:hover {
                        color: white !important;
                    }
                }

                &:nth-child(even){
                    a {
                        padding-left:15px;
                    }
                }
            }
        }
    }
}



/**-----FOOTER END-------**/
