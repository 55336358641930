//Define Colors
$color-primary: #e4ce00;
$color-primary-dark: #000;
$color-secondary: #231f20;
$color-third: #aaa9a9;
$color-fourth: #656363;
$color-fifth: #f26522;
$color-sixth: #00d600;
$color-sixth: #ffffff;

